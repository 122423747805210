.badge {
  display: inline-flex;
  font-weight: 400;
  background: #f4f4f4;
  padding-right: 12px;
  color: #a3a3a3;
  color: var(--color);
  border-radius: 4px;
  align-items: center;
  margin-right: 6px;
  margin-bottom: 10px;
  line-height: 34px;
}

.badge .btn {
  width: 15px;
  margin-left: 8px;
  padding: 0;
}

.badge .btn:hover {
  background: none;
}

.dark .badge {
  background-color: var(--bg-secondary);
}
