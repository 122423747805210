.results {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 30px;
}

.results h2 {
  margin-bottom: 3px;
}

.results .items {
  list-style: none;
  margin: 0;
  padding: 0;
}

.results .items > li {
  margin-bottom: 30px;
  border: 1px solid var(--border);
  border-radius: 5px;
  padding: 10px;
}

.results .items header {
  display: flex;
  font-weight: 500;
  font-size: 14px;
  align-items: center;
  margin-bottom: 10px;
}

.list {
  list-style: none;
  margin: 5px -10px -5px;
  padding: 0;
}

.list li {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: 5px 10px;
}

.list li:last-child {
  border-bottom: none;
}

.list li label {
  opacity: 0.64;
}

.stats {
  border-radius: 5px;
  font-size: 12px;
  color: var(--color-secondary);
  margin-bottom: 15px;
}

.itemIndex {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 11px;
  padding: 0;
  height: 24px;
  width: 24px;
  background: #d2ffd0;
  color: #13482b;
  border-radius: 3px;
  margin-right: 8px;
}
