.main {
  --bg: #fff;
  --bg-secondary: #f1f3f5;
  --bg-header: #fff;
  --bg-overlay: #fff;
  --bg-input: #f4f4f4;
  --bg-input-hover: #f0f0f0;
  --color: #000;
  --color-secondary: #495057;
  --color-action: #0008e2;
  --color-brand-dark: #0000ff;
  --border: #e9ecef;
  --border-light: rgba(153, 153, 153, 0.2);
  background: var(--bg);
  color: var(--color);
}

.main.dark {
  --bg: #0b0c15;
  --bg-secondary: #151620;
  --bg-header: #1a1b20;
  --bg-darker: rgb(29, 30, 39);
  --bg-darkest: rgb(21, 21, 27);
  --bg-overlay: var(--bg-darkest);
  --bg-input: rgb(45, 46, 59);
  --bg-input-hover: #313443;
  --color: #fff;
  --color-secondary: rgb(179, 179, 179);
  --border: #303236;
  --border-light: #ffffff09;
}

html {
  background-color: var(--bg);
}
