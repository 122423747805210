.footerWrapper {
  margin-top: auto;
}

.footer {
  position: relative;
  border-top: 60px solid var(--bg);
  background: var(--bg-secondary);
  color: var(--color-secondary);
  padding: 30px 0;
}

.footer .wr {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
}

.footerLinks {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
  max-width: 800px;
}

.footerLinks > li {
  /*margin-left: 50px;*/
}

.footerLinks > li > span {
  font-weight: 500;
}

.footerLinks > li > ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.footerLinks > li > ul > li {
  padding: 3px 0;
  font-size: 13px;
}

.footerLinks a {
  font-weight: 500;
  text-decoration: none;
}

.footerLinks a:hover {
  text-decoration: underline;
}

@media (max-width: 800px) {
  .footer .wr {
    flex-direction: column;
  }

  .footerLinks {
    margin-top: 30px;
  }

  .footerLinks li {
    margin-left: 0;
  }
}

@media (max-width: 600px) {
  .footerLinks > li {
    width: 200px;
    margin-bottom: 15px;
  }
}
