/* declare our button colors in here so we dont end up with 1000 colors 
   in the main css colors file */
.main {
  --btn-bg: #eff2ff;
  --btn-bg-hover: #96969621;
  --btn-color: #01018d;
  --btn-color-secondary: rgb(80, 80, 80);
  --btn-border: #9f9f9f2e;
}

.dark.main {
  --btn-bg: #ffffff09;
  --btn-bg-hover: #96969621;
  --btn-color: #fff;
  --btn-color-secondary: rgba(255, 255, 255, 0.623);
  --btn-border: #ffffff17;
}

.btn {
  display: inline-flex;
  font-size: 14px;
  height: 34px;
  line-height: 30px;
  padding: 0 10px;
  box-sizing: border-box;
  border-radius: 5px;
  font-weight: 500;
  text-decoration: none;
  color: var(--btn-color);
  background: var(--btn-bg);
  cursor: pointer;
  align-items: center;
  justify-content: center;
  outline: none;
  white-space: nowrap;
  transition: all 0.1s ease-in-out;
  transition-property: background, color;
  border: none;
  font-family: inherit;
}

.btn:active {
  background: #40267131;
}

.btn:disabled {
  opacity: 0.6;
  cursor: not-allowed;
  color: var(--btn-color-secondary) !important;
  background-color: var(--btn-bg) !important;
}

.btn:hover {
  color: #221d2b;
  background: var(--btn-bg-hover);
}

.dark .btn:hover {
  color: #fff;
}

.btn--light {
  color: #fff !important;
}

.btn--clear {
  box-shadow: none !important;
  background: transparent !important;
}

.btn--large {
  font-size: 16px;
  height: 40px;
  padding: 0 15px;
}

.btn--small {
  font-size: 12px;
  height: 28px;
  line-height: 28px;
  padding: 0 9px;
  font-weight: 500;
}

.btn--small:hover {
  background: var(--btn-bg);
  color: var(--btn-color);
  filter: brightness(0.96) saturate(1.5);
}

.btn--primary,
.btn--primary.btn--loading:hover {
  background: #2c2cdb;
  color: #fff;
}

.btn--primary:hover,
.btn--primary.btn--loading,
.btn--primary.btn--loading:hover {
  color: #fff;
  background: #271fdb;
}

.btn--secondary {
  background: transparent;
  color: var(--btn-color-secondary);
}

.btn--secondary.btn--loading {
  color: var(--btn-color);
}

.btn--danger {
  color: #d42434;
  border-color: #ffecee;
  background: #ffecee;
}

.dark .btn--danger {
  color: #ff4051;
  background: #6d000036;
  box-shadow: inset 0 0 0 1px #9e2b352d;
}

.btn--danger:hover {
  color: #d42434;
  background: #ffdde0;
}

.dark .btn--danger:hover {
  color: #ff0015;
  background: #9e000036;
  box-shadow: inset 0 0 0 1px #9e2b352d;
}

.btn--loading,
.btn--loading:hover {
  cursor: wait;
  background-color: var(--btn-bg-hover);
}

.btn--loading:after {
  content: '';
  height: 12px;
  width: 12px;
  border: 2px solid #85808e;
  border-right-color: #40267128;
  border-radius: 50%;
  margin-left: 8px;
  animation: btnLoading;
  animation-duration: 0.8s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.btn--primary.btn--loading:after {
  border-color: #ffffff;
  border-right-color: #ffffff59;
}

.btn--secondary.btn--loading:after {
  border-color: #8b8b8b;
  border-right-color: #d4d4d4;
}

.dark .btn--loading:after,
.btn--light.btn--loading:after {
  border-color: rgba(255, 255, 255, 0.726);
  border-right-color: transparent;
}

.btn .chevronDown {
  height: 0;
  width: 0;
  border: 5px solid transparent;
  border-top-color: inherit;
  opacity: 0.4;
  margin-left: 6px;
  margin-top: 5px;
}

.btn--ellipsis {
  background-color: #fff;
  margin-left: 10px;
}

.dark .btn--ellipsis {
  background-color: var(--bg-secondary);
}

.btn--ellipsis:hover {
  background-color: var(--bg-input-hover);
}

.btn svg {
  width: 15px;
}

.btn--play::after {
  content: '';
  height: 0;
  width: 0;
  border: 5px solid transparent;
  border-left-color: inherit;
  margin-left: 6px;
  border-left-width: 7px;
}

@keyframes btnLoading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
