.chart {
  /* we will our parent size */
  width: 100%;
  height: 100%;
}

.chart .ticks {
  color: rgb(175, 175, 175);
}

.chart .ticks line {
  fill: none;
  stroke: #f5f5f5;
  stroke-width: 1;
  shape-rendering: crispEdges;
  pointer-events: none;
}

.dark .chart .ticks line {
  stroke: var(--border-light);
}

.chart .multiple-lines .ticks line {
  stroke-width: 0;
}

.chart .ticks text {
  font-size: 12px;
}

.chart .domain {
  fill: none;
  stroke: var(--border-light);
  stroke-width: 1;
  shape-rendering: crispEdges;
  pointer-events: none;
}

.chart .line-overlay {
  fill: none;
  pointer-events: all;
}

.chart .hover-line {
  stroke: rgba(0, 0, 0, 0.493);
  stroke-width: 1px;
  stroke-dasharray: 1 2;
}

.dark .chart .hover-line {
  stroke: rgba(255, 255, 255, 0.335);
}

.chart:hover .hover-line {
  stroke: rgba(0, 0, 0, 0.726);
  stroke-dasharray: none;
}

.dark .chart:hover .hover-line { 
  stroke: rgba(255, 255, 255, 0.376);
}

.chart .axis {
  font: 10px sans-serif;
}

.chart .axis path,
.chart .axis line {
  fill: none;
  stroke-width: 1px;
  shape-rendering: crispEdges;
}

/* thinner lines in dashboards */
.grid .chart .line {
  stroke-width: 1.2;
}

.multi-chart-wrapper {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
}
