.nestedNavContent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 30px 0 0 30px;
  max-width: 740px;
  margin-left: 170px;
}

.nestedNav {
  position: absolute;
  top: 10px;
  bottom: 0;
  display: flex;
  flex-direction: column;
  border-right: 1px solid var(--border);
  width: 170px;
  padding: 60px 0 30px;
}

.nestedNav h4 {
  margin-top: 14px;
  font-size: 15px;
  font-weight: 600;
}

.nestedNav > ul {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
}

.nestedNav > ul > li {
  margin-bottom: 20px;
}

.nestedNav > ul > li > span {
  font-weight: 400;
  font-size: 13px;
  text-transform: uppercase;
  opacity: 0.6;
}

.nestedNav > ul > li > ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.nestedNav > ul > li > ul > li {
  width: 100%;
}

.nestedNav a {
  display: block;
  padding: 6px 0;
  text-decoration: none;
  color: inherit;
}

.nestedNav a:hover {
  color: var(--color-action);
}

.nestedNav .active a {
  font-weight: 500;
  color: var(--color-action);
}
