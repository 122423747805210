.valueProps {
  display: flex;
  flex-direction: column;
  border-left: 1px solid var(--border);
  background-color: var(--bg-secondary);
  width: 280px;
  border-radius: 0 5px 5px 0;
  padding-top: 10px;
  box-sizing: border-box;
}

.valueGraphic img {
  max-width: 100%;
}

.valueProps .freeTier {
  padding: 0px 30px 30px 30px;
  font-size: 13px;
}

.valueProps .freeTier ul {
  margin: 0;
  padding-left: 15px;
  color: var(--color-secondary);
}

.valueProps .freeTier ul li {
  padding-top: 3px;
}

@media (max-width: 800px) {
  .valueProps {
    display: none;
  }
}
