.toast {
  position: fixed;
  left: 50px;
  bottom: 50px;
  display: flex;
  min-height: 30px;
  align-items: center;
  box-shadow: 0 3px 7px #0000002b, 0 10px 30px #0000001f;
  border-radius: 5px;
  padding: 7px 14px;
  font-weight: 500;
  opacity: 0;
  transform: perspective(600px) scale(0.8) translate(0, 50px);
  transition: all 0.2s ease-in-out;
  font-size: 15px;
  z-index: -1;
  color: #fff;
  background: #29313d;
  -moz-osx-font-smoothing: initial;
  -webkit-font-smoothing: initial;
}

.toast.visible {
  opacity: 1;
  z-index: 999;
  transform: perspective(600px) scale(1) translate(0, 0);
}

.toast--success {
  background: #27ac62;
}

.toast--error {
  background: #f34646;
}

.toast a {
  display: block;
  margin-left: 5px;
  background: #0000002b;
  color: #fff;
  text-decoration: none;
  padding: 7px 9px;
  border-radius: 3px;
  margin-left: 12px;
  margin-right: -7px;
}