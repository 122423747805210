.docsPath {
  display: flex;
  margin: 0;
  margin-bottom: 25px;
  padding: 0;
  list-style: none;
}

.docsPath li {
  margin-right: 5px;
  text-transform: uppercase;
  font-weight: 500;
  color: var(--color-secondary);
}

.docsWrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 30px 0 0 40px;
  margin-left: 170px;
  max-width: 740px;
  min-height: calc(100vh - 120px);
}

.docsContent {
  font-size: 15px;
}

.docsContent h2 {
  margin-top: 35px;
  margin-bottom: 6px;
  font-size: 23px;
}

.docsContent h2:first-child {
  margin-top: 0;
}

.docsContent h4 {
  display: flex;
  align-items: center;
  margin-top: 35px;
  margin-bottom: 8px;
}

.docsContent .step {
  background: #915ee7;
  color: #fff;
  height: 20px;
  min-width: 20px;
  text-align: center;
  line-height: 20px;
  border-radius: 10px;
  font-size: 14px;
  margin-right: 7px;
}

.docsContent .step.last {
  position: relative;
  background: #6fdb6f;
}

.docsContent .step.last svg {
  position: absolute;
  top: 3px;
  left: 2px;
}

.docsContent p {
  margin-top: 0;
  margin-bottom: 20px;
  line-height: 25px;
}

.docsContent a {
  color: var(--color-action);
  text-decoration: none;
  font-weight: 600;
}

.docsContent .optional,
.docsContent .secondary {
  color: var(--color-secondary);
}

.docsContent .optional a {
  font-weight: 500;
}

.docsContent .tab-1 {
  margin-left: 27px;
}

.docsContent .contextMenu {
  display: inline-block;
  height: 5px;
  width: 5px;
  overflow: hidden;
  border-radius: 5px;
  background: #5d5d5d;
  box-shadow: 0 7px 0, 0 -8px 0;
  margin: 0 3px;
  margin-bottom: 2px;
  color: #5d5d5d;
}

.docsContent .info {
  font-size: 14px;
  line-height: 20px;
  padding: 12px;
  border-radius: 3px;
  background: #777dff27;
}

.docsContent .important {
  background: #ffce801f;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding: 12px;
  border-radius: 3px;
}

.docsContent .code {
  background: #2d303f;
  color: #fff;
  font-weight: 600;
  padding: 15px;
  display: block;
  border-radius: 4px;
  white-space: pre;
}

.boxPicker {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
}

.boxPicker li {
  display: block;
  width: calc(33.33% - 20px);
  margin-right: 10px;
}

.boxPicker li a {
  display: block;
  border: 1px solid var(--border);
  border-radius: 4px;
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
  cursor: pointer;
  text-decoration: none;
}

.boxPicker a:hover {
  border-color: var(--color-action);
}

.docsContent .table {
  border-collapse: collapse;
  border: 1px solid var(--border);
  margin: 10px 0;
}

.docsContent .table th,
.docsContent .table td {
  text-align: left;
  padding: 10px 10px;
  font-size: 13px;
  border-bottom: 1px solid var(--border);
}

.docsContent .table th {
  font-weight: 500;
}

.docsContent .table tr:last-child td {
  border-bottom: none;
}
