.form {
}

.form h3 {
  margin: 0;
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 23px;
}

.formRow {
  margin-bottom: 30px;
}

.formRow > label {
  font-weight: 500;
  display: block;
  margin-bottom: 4px;
}

.formRow > input,
.formInput {
  border-radius: 3px;
  line-height: 40px;
  padding: 0 10px;
  border: none;
  border: 1px solid var(--border);
  background: var(--bg-input);
  color: var(--color);
  width: 300px;
  outline: none;
  transition: all 0.15s linear;
  transition-property: border-color box-shadow;
  font-feature-settings: 'cv08', 'cpsp', 'ss01', 'ss02';
}

.formRow > input:focus {
  border-color: #0a84ff;
  box-shadow: 0 0 0 2px #0a84ff27;
}

.formRow > .btn {
  height: 34px;
  transform: translateX(-100%);
  margin-left: -4px;
}

.formError {
  background: #ff7b7b4d;
  color: #a92e2d;
  font-size: 14px;
  padding: 10px;
  border-radius: 2px;
  margin-bottom: 13px;
  line-height: 22px;
}

.formError:empty {
  display: none;
}

.formError::first-letter {
  text-transform: uppercase;
}

.fromActions {
  display: flex;
  justify-content: space-between;
}

.valueInput {
  height: 34px;
  background: var(--bg-input);
  box-shadow: inset 0 0 0 1px var(--btn-border);
  color: var(--color);
  border-radius: 4px;
  line-height: 34px;
  padding: 0 10px;
  border: none;
  outline: none;
}

.valueInput:hover {
  box-shadow: inset 0 0 0 1px #ffffff52;
}

.valueInput:focus {
  box-shadow: inset 0 0 0 1px rgb(100, 153, 255);
}

.valueInput.small {
  height: 30px;
  font-size: 13px;
}
