.upload {
  justify-content: flex-start;
  border: 1px solid var(--border);
  padding: 15px;
  margin-top: 30px;
  border-radius: 5px;
  font-weight: 500;
  position: relative;
  overflow: hidden;
}

.uploadBar {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  bottom: 0;
  background: var(--bg-secondary);
  transition: width 0.1s linear;
}

.uploadContent {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.upload :global .loading {
  flex-grow: 0;
  margin-right: 10px;
}

.upload :global .loading .loader {
  margin: 0;
}

.mapping {
  border: 1px solid var(--border);
  list-style: none;
  margin: 30px 0;
  padding: 0;
  border-radius: 5px;
}

.mapping li {
  display: flex;
  align-items: center;
  padding: 5px 15px;
  border-bottom: 1px solid var(--border);
}

.mapping li input {
  color: var(--color);
  height: 24px;
  background-color: transparent;
  outline: none;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 0 6px;
}

.mapping li input:disabled {
  color: var(--color-secondary);
}

.mapping li label {
  display: flex;
  align-items: center;
  margin-left: 30px;
  padding: 5px 0;
}

.mapping li label:hover {
  color: var(--color-action);
  cursor: pointer;
}

.mapping li label input {
  margin-right: 5px;
}

.mapping li:first-child {
  font-weight: 500;
}

.mapping li span:first-child {
  width: 200px;
}

.mapping li:last-child {
  border-bottom: none;
}
