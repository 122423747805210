@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: local('Inter Regular'), local('Inter UI Regular'),
    url('../fonts/Inter-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: local('Inter Medium'), local('Inter UI Medium'),
    url('../fonts/Inter-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: local('Inter Semi Bols'), local('Inter UI Semi Bold'),
    url('../fonts/Inter-SemiBold.woff2') format('woff2');
}

body {
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica,
    Arial, sans-serif;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
  font-style: normal;
  color: #24292e;
  background-color: #fff;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  font-feature-settings: 'tnum', 'ss01', 'calt', 'liga', 'kern';
  margin: 0;
}

.main {
  display: flex;
  min-height: calc(100vh + 120px);
  flex-direction: column;
}

.wr {
  display: flex;
  flex-direction: column;
  width: calc(100% - 60px);
  max-width: 860px;
  margin: 0 auto;
  padding: 0 30px;
}

.wr--centered {
  align-items: center;
}

.wr--fullWidth {
  width: calc(100% - 20px);
  max-width: none;
  padding: 0 10px;
}

a {
  color: var(--color-action);
  font-weight: 500;
}

mark {
  color: inherit;
  background-color: rgba(255, 255, 0, 0.438);
  box-shadow: 0 0 0 3px rgba(255, 255, 0, 0.438);
  border-radius: 3px;
}

.dark mark {
  background-color: rgba(255, 255, 0, 0.199);
  box-shadow: 0 0 0 3px rgba(255, 255, 0, 0.199);
}
