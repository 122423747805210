.wrapper {
  display: flex;
  flex-direction: column;
  padding: 0;
}

.wrapper h3 {
  margin-bottom: 0;
}

.container {
  margin-bottom: 20px;
}

.rows {
  margin: 10px 0;
  padding: 0;
  list-style: none;
}

.row {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 15px;
}

.items .row {
  padding-left: 34px;
}

.row > button {
  margin-bottom: 5px;
}

.item {
  display: flex;
  align-items: center;
  padding: 0 6px 0 10px;
  margin-right: 5px;
  margin-bottom: 5px;
  min-height: 34px;
  background-color: #f6f6f9fa;
  border-radius: 5px;
  font-size: 13px;
}

.item.purple {
  background: #f6ebff;
  color: #5a15b3;
}

.item.green {
  background: #d2ffd0;
  color: #13482b;
}

.item.operator {
  font-weight: 500;
  font-size: 11px;
}

.item.itemIndex {
  position: absolute;
  left: 0;
  top: 0;
  justify-content: center;
  font-weight: 500;
  font-size: 11px;
  padding: 0;
  width: 30px;
}

.item > input {
  color: var(--color);
  height: 24px;
  background-color: transparent;
  outline: none;
  box-sizing: border-box;
  border: 1px solid var(--border);
  background: var(--bg);
  border-radius: 5px;
  padding: 0 6px;
}

.item > label {
  display: flex;
  align-items: center;
  color: var(--color-secondary);
  margin-right: 10px;
  font-size: 12px;
}

.item > select {
  background: var(--bg);
  height: 26px;
  color: var(--color);
  border: 1px solid var(--border);
  border-radius: 5px;
  font-size: 13px;
  padding: 0 2px;
}

.item > .select {
  background: var(--bg);
  border: 1px solid var(--border);
  border-radius: 5px;
}
