.ckbox {
  display: block;
  position: relative;
  width: 22px;
  height: 22px;
  box-shadow: inset #c9c9c9 0 0 0 1px;
  border-radius: 3px;
  transition: background-color 0.13s ease;
  outline: none;
  cursor: pointer;
}

.ckbox:hover {
  box-shadow: inset #c9c9c9 0 0 0 2px;
}

.ckbox.checked {
  transition: background-color 0.13s ease;
  box-shadow: inset #363b44 0 0 0 1px;
}

.dark .ckbox.checked {
  box-shadow: inset var(--color) 0 0 0 1px;
}

.ckbox::after {
  display: block;
  content: '';
  position: absolute;
  left: 8px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid #363b44;
  border-width: 0 2px 2px 0;
  opacity: 0;
  transform: scale(0.4) rotate(45deg);
  transition: opacity 0.15s ease 0s, transform 0.15s ease 0s;
}

.ckbox.checked::after {
  opacity: 1;
  transform: scale(1) rotate(45deg);
}

.dark .ckbox.checked::after {
  border-color: var(--color);
}