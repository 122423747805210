.usageTable {
  border-collapse: collapse;
  border: 1px solid var(--border);
  width: 500px;
  font-size: 13px;
  margin-top: 20px;
}

.usageTable th {
  font-weight: 500;
  background-color: var(--bg-secondary);
}

.usageTable td,
.usageTable th {
  text-align: right;
  padding: 10px 10px;
  border-bottom: 1px solid var(--border-light);
}

.usageTable th {
  padding: 6px 10px;
}

.usageTable td:first-child,
.usageTable th:first-child {
  text-align: left;
  width: 100px;
}
