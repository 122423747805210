.react-switch-bg {
  background: var(--bg-input) !important;
  height: 20px !important;
  width: 30px !important;
}

.react-switch-handle {
  height: 14px !important;
  width: 14px !important;
  margin-top: 2px;
}
.react-switch-bg svg {
  display: none;
  visibility: hidden;
}

.checked .react-switch-bg {
  background: rgb(94, 106, 210) !important;
}

.checked .react-switch-handle {
  transform: translateX(12px) !important;
}
