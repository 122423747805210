.notice {
  font-size: 14px;
  line-height: 20px;
  padding: 12px;
  border-radius: 3px;
  background: #777dff27;
  font-weight: 500;
}

.notice--warning {
  background: #ffc56938;
  font-size: 14px;
  line-height: 20px;
  padding: 12px;
  border-radius: 3px;
}