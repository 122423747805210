.authWrapper {
  width: 100%;
  min-height: 100vh;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 100px;
  min-height: 100vh;
  box-sizing: border-box;
  z-index: 0;
}

.authWrapper header {
  position: relative;
  padding: 30px;
  color: var(--color);
  box-sizing: border-box;
  margin: 0 auto;
  z-index: 1;
}

.authWrapper header svg {
  position: relative;
  width: 100px;
}

.authTitle {
  position: relative;
  font-weight: 400;
}

.authBox {
  display: flex;
  margin-top: 0;
  margin-bottom: 30px;
  position: relative;
  border-radius: 5px;
  border: 1px solid #e6e6e6;
  box-shadow: 0 23px 37px -10px #0000000d;
  background-color: var(--bg);
}

.dark .authBox {
  border: none;
  box-shadow: none;
  background: var(--bg-darker);
}

.authForm {
  display: flex;
  flex-direction: column;
  width: 340px;
  margin: 30px;
}

.authForm input,
.authForm select {
  display: block;
  height: 46px;
  position: relative;
  padding: 0 14px;
  background: var(--bg-input);
  border-radius: 3px;
  font-size: 16px;
  border: none;
  color: var(--color);
  outline: none;
  width: 100%;
  box-sizing: border-box;
}

.authForm input::-webkit-input-placeholder {
  color: #8b8e91;
}

.dark .authForm input::-webkit-input-placeholder {
  color: var(--bg);
}

.authForm input:active,
.authForm input:focus {
  box-shadow: 0 0 0 1px #2f1f4c;
}

.authForm input:disabled {
  cursor: not-allowed;
  color: #6a6e72;
  pointer-events: none;
}

.authFormSection {
  margin-bottom: 20px;
}

.authFormSection--columns {
  display: flex;
  flex-basis: auto;
}

.authFormSection > label {
  display: block;
  color: var(--color-secondary);
  margin-bottom: 5px;
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
}

.authFormNotice {
  display: flex;
  font-size: 14px;
  margin-bottom: 20px;
}

.authFormNotice .ckbox {
  flex-shrink: 0;
  margin-top: 2px;
  margin-right: 9px;
}

.authFormNotice a {
  color: #000000;
}

.dark .authFormNotice a {
  color: var(--color);
}

.authFormError {
  background: #ffdbdb;
  color: #a92e2d;
  font-size: 14px;
  padding: 10px;
  border-radius: 2px;
  margin-bottom: 13px;
  line-height: 22px;
}

.authFormError:empty {
  display: none;
}

.authFormActions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.authFormActions a.plain {
  text-decoration: none;
  color: inherit;
}

.authFormActions a.plain:hover {
  text-decoration: underline;
}

@media (max-width: 400px) {
  .authBox {
    border-radius: 0;
  }

  .authForm {
    width: calc(100vw - 60px);
  }
}

.authBackdrop {
  position: absolute;
  bottom: 50%;
  right: -50%;
  top: -50%;
  left: -50%;
  background: linear-gradient(45deg, #f1f2f5 0%, rgb(255, 255, 255) 100%);
  transform: rotate(-15deg);
  z-index: -1;
}
