.label {
  display: inline-block;
  font-weight: 500;
  font-size: 10px;
  text-transform: uppercase;
  background: #f4f4f4;
  padding: 0 4px;
  color: #7a7a7a;
  border-radius: 2px;
  margin-left: 6px;
}

.dark .label {
  background-color: var(--bg-secondary);
}

.label--new {
  background-color: #abfbd4 !important;
  color: #1f6442;
  opacity: 1;
  animation: labelFade;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
}

.label--public {
  background-color: #abd7fb !important;
  color: #1f2964;
}

.label--good {
  background-color: #abfbd4 !important;
  color: #1f6442;
}

.label--bad {
  background-color: #fbabab !important;
  color: #641f1f;
}

@keyframes labelFade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
