.info {
    font-size: 12px;
}

.info a {
    color: var(--color-secondary);
    text-decoration: none;
}

.info a:hover {
    text-decoration: underline;
}