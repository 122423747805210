.overlay__settings {
  min-width: 240px;
  margin: 0;
  padding: 0;
  list-style: none;
  white-space: nowrap;
  margin: 5px;
}

.overlay__settings .divider {
  cursor: default;
  padding: 0;
  margin: 5px -5px;
  border-bottom: 1px solid var(--border);
  border-radius: 0;
}

.overlay__setting {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
}

.overlay__setting.indented {
  margin-left: 10px;
}

.flex-center {
  display: flex;
  align-items: center;
}

.overlay__setting .flex-center span {
  margin-right: 5px;
}
