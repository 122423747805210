.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
}

.loader {
  font-size: 10px;
  color: transparent;
  height: 18px;
  width: 18px;
  margin: 40px;
  border: 2px solid #ced4da;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spin;
  animation-duration: 0.6s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  box-sizing: border-box;
}

.dark .loader {
  border-color: rgba(255, 255, 255, 0.246);
  border-right-color: transparent;
}

.loading--small .loader {
  margin: 10px;
}

.loadingText {
  font-weight: 500;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
