.container {
  display: flex;
  align-items: center;
}

.list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 2px 0;
  padding: 0;
}

.list li {
  background: #f3e2ff;
  color: #571388;
  border-radius: 3px;
  padding-left: 7px;
  margin: 2px 5px 2px 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  cursor: pointer;
}

.list li:hover {
  color: #d42434;
  background: #ffd7e1;
}

.list li button {
  background: transparent;
  border: none;
  outline: none;
  padding: 0;
  height: 20px;
  width: 20px;
  line-height: 20px;
  font: inherit;
  color: inherit;
  cursor: pointer;
}

.form {
  display: flex;
}

.form input {
  color: var(--color);
  height: 24px;
  font-size: 12px;
  background-color: transparent;
  outline: none;
  box-sizing: border-box;
  border: 1px solid var(--border);
  border-right: none;
  background: var(--bg);
  border-radius: 4px 0 0 4px;
  padding-left: 5px;
}

.form button {
  border: none;
  outline: none;
  padding: 0;
  height: 24px;
  width: 40px;
  font: inherit;
  cursor: pointer;
  border-radius: 0 4px 4px 0;
  font-weight: 500;
  background: #d8dcee;
  color: #01018d;
  font-size: 12px;
}
