.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 70px 0;
}

.empty h2 {
  margin: 0;
}

.empty p {
  margin: 0;
}

.empty .btn {
  margin-top: 25px;
}
