.modalWrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.52);
  display: flex;
  justify-content: center;
  z-index: 30;
  overflow: scroll;
  z-index: 101;
}

.modal {
  position: relative;
  margin: 0 30px;
  margin-top: 5vh;
}

.modalContent {
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 30px;
  border-radius: 5px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.23);
}

.modalContent h2 {
  margin: 0;
}

.modalContent p {
  font-size: 15px;
  margin-top: 5px;
}

.modalLegal {
  white-space: pre-wrap;
}

.modalActions {
  display: flex;
  margin: 0 -5px;
  margin-top: 20px;
  justify-content: flex-end;
}

.modalActions .btn {
  margin: 0 5px;
}
