.header {
  z-index: 101;
}

.header .wr {
  height: 46px;
  flex-direction: row;
  align-items: center;
  border: 1px solid #dee2e6;
  background: var(--bg-header);
  margin-top: 10px;
  margin-bottom: 5px;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 0 10px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.03);
}

.header .logoLink {
  text-decoration: none;
  color: inherit;
}

.header .logo {
  margin: 0 10px 0 5px;
  font-size: 20px;
}

.header .logo svg {
  width: 22px;
  margin-top: 7px;
}

.header nav {
  display: flex;
  align-items: center;
}

.header nav a {
  height: 30px;
  line-height: 30px;
  margin-left: 10px;
  text-decoration: none;
  font-size: 15px;
  color: var(--color);
}

.header nav a.disabled {
  pointer-events: none;
  opacity: 0.6;
}

.header nav a:hover {
  text-decoration: underline;
}

.header .spacer {
  flex-grow: 1;
}

.header nav.secondary a {
  color: var(--color-secondary);
}

.header nav.secondary > * {
  margin-left: 10px;
}

.headerProfile {
  border-radius: 50%;
  background: #5a5aff;
  height: 26px;
  width: 26px;
  line-height: 26px;
  text-align: center;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  margin-left: 8px;
}

.headerBanner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  color: #fff;
  font-weight: 500;
  font-size: 13px;
}

.headerBanner .btn {
  margin: 0 8px;
}

.headerBanner.info {
  background-color: #4f4fbd;
}

/* subheader */

.subheader {
  position: sticky;
  top: 0;
  background: var(--bg);
  /*box-shadow: 0 1px #eaeaea;*/
  z-index: 100;
}

.subheader .wr {
  padding: 0 15px;
}

.dark .subheader {
  box-shadow: 0 1px #0000000d;
}

.subheader.sticky .wr {
  transition: all 0.2s ease-in-out;
  transition-property: box-shadow;
  box-shadow: 0 0 15px 0 #0000001a;
  border-radius: 0 0 4px 4px;
}

.dark .subheader.sticky {
  box-shadow: 0 0 5px 0 #0000001f;
}

.subheader .wr {
  height: 48px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

/* we give it height so the header doesnt look too short */
.subheader:empty {
  height: 0;
}

/* to offset the 7px height */
.subheader.sticky:empty {
  top: -7px;
}

.subheader__meta {
  display: flex;
  align-items: center;
}

.subheader__meta h2 {
  margin: 0;
  font-weight: 500;
  font-size: 16px;
}

.subheader__meta .parent {
  position: relative;
  color: var(--color-secondary);
  font-size: 16px;
  text-decoration: none;
  margin-right: 20px;
}

.subheader__meta .parent:hover {
  text-decoration: underline;
}

.subheader__meta .parent::after {
  content: '';
  position: absolute;
  top: 4px;
  right: -11px;
  height: 18px;
  width: 1px;
  background: var(--color-secondary);
  transform: rotate(24deg);
  opacity: 0.9;
}

.subheader .actions {
  display: flex;
  align-items: center;
}

.subheader .actions > * {
  margin-left: 10px;
}

.subheader .actions input[type='number']::-webkit-inner-spin-button,
.subheader .actions input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.subheader .actions input[type='number'] {
  -moz-appearance: textfield;
}
