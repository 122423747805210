.notFound {
  text-align: center;
  margin: 100px auto;
}

.notFound h1 {
  font-size: 42pt;
  margin: 0;
}

.notFound p {
  font-size: 16pt;
  margin: 0;
  margin-bottom: 40px;
}

.notFound .btn {
}
