.subscription {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--border);
  border-radius: 5px;
  padding: 10px 20px;
}

.subscription .meta {
}

.subscription .meta h3 {
  margin: 0;
}

.plans {
  display: flex;
  flex-direction: column;
  margin: 15px 0 0 0;
  padding: 0;
  list-style: none;
}

.plans li {
  display: flex;
  align-items: center;
  border: 1px solid var(--border);
  border-radius: 5px;
  padding: 10px 15px;
  margin-bottom: 15px;
}

.plans li span:first-child {
  width: 120px;
  font-weight: 500;
}

.plans li span:nth-child(2) {
  width: 150px;
}

.plans li .price {
  margin-left: auto;
  font-weight: 500;
}

.plans li button {
  margin-left: 10px;
  margin-right: -5px;
}
