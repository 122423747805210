.list {
  margin: 0;
  margin-top: 40px;
  padding: 0;
  border-radius: 4px;
  border: 1px solid var(--border);
  list-style-type: none;
}

.list.withTitle {
  margin-top: 0;
}

.listTitle {
  display: flex;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 500;
  color: var(--color-secondary);
}

.listTitle img {
  height: 18px;
  margin-right: 5px;
}

.listItem {
  padding: 0 10px;
  border-bottom: 1px solid var(--border);
  min-height: 50px;
  display: flex;
  align-items: center;
}

.listItem .spacer {
  flex-grow: 1;
}

.listItem:last-child {
  border-bottom: none;
}

.listItem__title {
  display: block;
  font-weight: 500;
  font-size: 15px;
}

a.listItem__title {
  color: inherit;
  text-decoration: none;
  line-height: 40px;
}

a.listItem__title:hover {
  color: var(--color-action);
}

.listItem .delete-chart {
  visibility: hidden;
  display: none;
  margin-left: 10px;
}

.listItem:hover .delete-chart {
  visibility: visible;
  display: block;
  cursor: pointer;
}

.listItem__chart {
  display: flex;
  height: 20px;
}

.listItem__actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 200px;
  color: var(--color-secondary);
}

.listItem .secondary {
  color: var(--color-secondary);
}

.listItem__meta {
  text-align: right;
  padding: 7px 3px;
  margin-left: 30px;
}

.listItem__meta .primary {
  display: block;
  font-weight: 500;
  font-size: 13px;
}

.listItem__meta .secondary {
  display: block;
  font-size: 13px;
}