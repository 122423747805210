.box {
  border: 1px solid var(--border);
  border-radius: 5px;
  padding: 20px;
}

.box.noPadding {
  padding: 0;
}

.boxTitle {
  margin: 0;
  font-size: 20px;
  font-weight: 500;
}

.boxDivider {
  height: 1px;
  background-color: var(--border);
  margin: 20px -20px;
}

.boxActions {
  display: flex;
  justify-content: space-between;
}