.timerange {
  display: flex;
  align-items: center;
}

.timerange > label {
  color: var(--color-secondary);
  margin: 0 7px;
  font-size: 12px;
}

.timerange > select {
  background: var(--bg);
  height: 26px;
  color: var(--color);
  border: 1px solid var(--border);
  border-radius: 5px;
  font-size: 13px;
  padding: 0 2px;
}

.time,
.date {
  border: 1px solid var(--border);
  outline: none;
  width: 46px;
  line-height: 20px;
  text-align: center;
  border-radius: 5px;
  font-variant-numeric: tabular-nums;
  letter-spacing: 1px;
  margin: 0 2px;
}

.date {
  width: 100px;
}

.time::placeholder {
  font-size: 11px;
}

.time.invalid {
  border-color: red !important;
}
