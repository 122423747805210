.overlayWrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  --overlay-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
}

.overlayTrigger {
  padding: 0;
  margin: 0;
  background: transparent;
  border: none;
  outline: none;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.overlayTrigger .icon svg {
  display: block;
}

.overlay {
  position: absolute;
  display: flex;
  flex-direction: column;
  transform: translate(0, -100%);
  top: 0;
  left: calc(50% - 26px);
  background: #fff;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 24px;
  z-index: 8;
  cursor: default;
}

.dark .overlay {
  background-color: var(--bg-overlay);
}

.overlay.top-center {
  transform: translate(-50%, -100%);
  left: 50%;
}

.overlay.bottom-left {
  left: initial;
  right: -10px;
  top: calc(100% + 10px);
  transform: translate(0, 0);
}

.overlay.bottom-center {
  left: 50%;
  right: initial;
  top: calc(100% + 10px);
  transform: translate(-50%, 0);
}

.overlay::before {
  content: '';
  position: absolute;
  bottom: -6px;
  left: 20px;
  height: 12px;
  width: 12px;
  background: var(--bg-overlay);
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
  transform: rotate(45deg);
  z-index: -2;
}

.overlay.top-center::before {
  transform: translate(-50%) rotate(45deg);
  left: 50%;
}

.overlay.bottom-left:before {
  bottom: initial;
  top: -6px;
  left: initial;
  right: 16px;
}

.overlay.bottom-center:before {
  bottom: initial;
  top: -6px;
  left: 50%;
}

.overlay::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 13px;
  height: 15px;
  width: 30px;
  background: var(--overlay-background);
  z-index: -1;
}

.overlay.top-center::after {
  transform: translate(-50%);
  left: 50%;
  bottom: 1px;
}

.overlay.bottom-left::after {
  left: initial;
  bottom: initial;
  top: 0;
  right: 10px;
}

.overlay.bottom-center::after {
  left: initial;
  bottom: initial;
  top: 0;
  left: 50%;
  transform: translate(-50%);
}

.overlay__options {
  margin: 0;
  padding: 0;
  list-style: none;
  white-space: nowrap;
  margin: 5px;
}

.overlay__options li {
  display: flex;
  padding: 8px 8px;
  border-radius: 3px;
  font-size: 14px;
  line-height: 22px;
  color: var(--color);
  cursor: pointer;
  font-weight: 500;
  text-align: left;
  justify-content: flex-start;
  min-width: 100px;
}

.overlay__options li svg {
  margin-left: 5px;
}

.overlay__options li:hover {
  background: var(--bg-input-hover);
}

.overlay__options li a {
  display: block;
  width: 100%;
  color: var(--color);
  margin: -4px -7px;
  padding: 4px 7px;
  text-decoration: none;
  outline: none;
}

.overlay__options a:not(.btn) {
  color: inherit;
}

.overlay__options .btn {
  padding: 0;
  box-shadow: none;
  background: transparent !important;
  flex-grow: 1;
  justify-content: flex-start;
  margin: -8px 0;
}

.overlay__options .divider {
  cursor: default;
  padding: 0;
  margin: 5px -5px;
  border-bottom: 1px solid var(--border);
  border-radius: 0;
}

.overlay__options li.divider:hover {
  background: none;
}

.overlay__options .checkbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;
  flex-grow: 1;
}

.overlay__options .checkbox input {
  margin-right: 0 !important;
  margin-left: 7px;
}

.overlay__content {
  margin: 10px;
}

.overlay__nav {
  display: flex;
  margin: 0;
  padding: 5px;
  height: 26px;
  line-height: 26px;
  border-radius: 0 0 5px 5px;
  overflow: hidden;
  list-style: none;
}

.overlay__nav li {
  text-align: center;
  font-weight: 500;
  background: #fff;
  cursor: pointer;
  border-radius: 4px;
  padding: 0;
  margin: 0;
  flex-grow: 1;
}

.overlay__nav li.active {
  background: #f9f9f9;
  color: var(--color-action);
}

.overlay__nav li:hover {
  color: var(--color-action);
}

.overlay__subtitle {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  color: #444;
  margin-top: 11px;
  margin-bottom: 3px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.overlay__subtitle.first {
  margin-top: 3px;
}

.overlayDivider {
  border-bottom: 1px solid var(--light-border);
}

.overlay__content .overlayDivider {
  margin: 0 -10px;
}

.overlay__options .overlay__options--danger {
  color: #ff6363;
}

.overlay__options .overlay__options--danger:hover {
  background-color: rgba(255, 99, 99, 0.15);
  color: #ff6363;
}
