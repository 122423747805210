.usage {
  width: 220px;
  border-top: 1px solid var(--border);
  padding: 10px;
  color: var(--color-secondary);
}

.usage h4 {
  margin: 0;
  margin-bottom: 5px;
  font-size: 12px;
}

.usage ul {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 12px;
}

.usage li {
}

.usage li.values {
  display: flex;
  justify-content: space-between;
}

.usage li .bar {
  width: 100%;
  height: 3px;
  border-radius: 2px;
  background-color: var(--bg-input);
  margin-bottom: 10px;
}

.usage li .barValue {
  height: 3px;
  border-radius: 2px;
  background: var(--color-action);
  max-width: 100% !important;
  opacity: 0.8;
}
