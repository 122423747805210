.orgSwitcher {
  display: flex;
  height: 38px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--border);
  padding-top: 14px;
}

.orgSwitcher span {
  position: absolute;
  font-size: 10px;
  font-weight: 500;
  color: var(--color-secondary);
  left: 8px;
  top: 8px;
  text-transform: uppercase;
}